<template>
<div>
  <div>
    <h1>Mapa cargado</h1>
    
    <v-btn
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="reload()">
                                            Visualizar Mapa
                                        </v-btn>
    <Tableau 
        v-if="ulr != 'null'"
        :url="url" 
        :height="1000" 
        :width="1000" 
        
        ref="tableau">
    </Tableau>
  </div>
</div>
</template>
<script>


export default {

  data(){
    return {
      url:'',
      id : this.$route.params.id,
      map:[]
    }
  },
  methods:{
      reload(){
          window.location.reload()
      },
      async getMaps(){
            this.$http.get('maps/'+this.id)
            //this.$http.get('maps/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    this.map = response.body
                   this.url=this.map.url_tableau
                   
        })},
        
  },
  beforeMount(){
      this.getMaps()
  },
  mounted(){
      
  }
};
</script>